.footer {
    background-color: #434345;
    color: #fff;
    padding: 50px 15px 15px 15px;
    font-family: "Poppins", sans-serif;
}

.footer-content {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 192px;
    align-self: stretch;
}

.company-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;
    border-right: 1px solid white;
    padding-right: 90px;
}

.footer-vertical-line {
    background: #fff;
    height: auto;
    width: 1px;
}

.company-info-text {
    color: #fff;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21.954px; /* 156.812% */
    letter-spacing: -0.154px;
}

.company-logo-wrapper {
    display: flex;
    width: 264px;
    height: 88px;
    padding: 15px 31px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 5px;
    background: #fff;
}

.company-logo {
    width: 188px;
    height: 69px;
    flex-shrink: 0;
}

.container-logo-mobile {
    display: none;
}
.company-logo-wrapper-mobile {
    display: flex;
    width: 188px;
    height: 69px;
    border-radius: 5px;
    background: #fff;
    flex-direction: column;
    align-items: center;
}
.company-logo-mobile {
    width: 141px;
    height: 60px;
    display: flex;
    justify-content: center;
    gap: 8.45px;
}

.footer-nav {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* justify-content: space-between; */
    /* flex: 1 0 0; */
    gap: 19px;
}

.footer-services {
    display: flex;
    /* width: 158px; */
    flex-direction: column;
    align-items: flex-start;
    gap: 19px;
}

.footer-nav a {
    color: #fff;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 21.954px; /* 99.79% */
    letter-spacing: 0.22px;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-skip-ink: auto;
    text-decoration-thickness: auto;
    text-underline-offset: auto;
    text-underline-position: from-font;
}

.footer-services a {
    align-self: stretch;
    color: #fff;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 21.954px; /* 137.211% */
    letter-spacing: 0.16px;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-skip-ink: auto;
    text-decoration-thickness: auto;
    text-underline-offset: auto;
    text-underline-position: from-font;
}

.footer-services h4 {
    align-self: stretch;
    color: #fff;
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 21.954px; /* 99.79% */
    letter-spacing: 0.22px;
}

.footer-bottom {
    display: flex;
    justify-content: center;
    gap: 17px;
    align-self: stretch;
    flex-wrap: wrap;
    margin-top: 16px;
    padding: 16px 0;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
}

.newsletter a {
    display: flex;
    padding: 6px 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 5px;
    border: 1px solid #fff;
}

.newsletter a:hover {
    background-color: #555;
}

.contact-info-container {
    display: flex;
    gap: 10px;
    justify-content: space-between;
}
.contact-info span {
    color: #fff;
    leading-trim: both;
    text-edge: cap;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
    letter-spacing: -0.154px;
}

.search input {
    border: 1px solid #555;
    border-radius: 5px;
    padding: 5px 10px;
    font-size: 14px;
    color: #333;
}

.developer-attribution {
    display: flex;
    justify-content: center;
    text-align: center;
    margin-top: 20px;
    font-size: 14px;
    color: #888;

    img {
        height: 27px;
    }
}

.developer-attribution a {
    color: #fff;
    text-decoration: none;
}

.company-info-text-mobile {
    display: none;
}

@media (max-width: 1568px) {
    .footer {
        padding: 20px 16px;
    }

    /* .footer-nav {
        flex-direction: column;
        display: flex;
        flex-direction: column;
        gap: 30px;
    } */
}

@media (max-width: 740px) {
    .footer {
        padding: 20px 16px;
    }
    .company-info {
        display: none;
    }

    .footer-nav {
        flex-direction: column;
        justify-content: space-between;
        display: flex;
        flex-direction: column;
        /* gap: 2px; */
        font-size: 10px;
        flex: 1 0;
        height: 100%;
    }

    .footer-nav a {
        font-size: 14px;
    }

    .footer-services a {
        font-size: 14px;
    }

    .footer-content {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: flex-start;
        gap: 12px;
        /* height: 365px; */
    }
    .footer-bottom {
        gap: 10px;
        border-bottom: none;
    }

    .container-logo-mobile {
        display: flex;
        justify-content: center;
        gap: 8.45px;
        border-bottom: 1px solid white;
        padding-bottom: 20px;
    }
    .contact-info-container {
        width: 100%;
    }
    .contact-info span {
        font-size: 12px;
    }
    .company-info-text-mobile {
        display: block;
        font-size: 12px;
        padding-top: 20px;
    }
    .newsletter,
        button {
            width: 100%;
        }
}

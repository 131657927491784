.blog-title {
    color: #bf2e1b;
    font-family: Poppins;
    font-style: normal;
    font-weight: 700;
    line-height: 1.2;
    text-transform: capitalize;
}

.blog-description {
    border-left-width: 3px;
    border-left-color: #bf2e1b;
    padding-left: 18px;
}

.continue-reading {
    color: #bf2e1b;
    font-family: Poppins;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
    margin: 0 0 20px 0 !important;
}

.blog-content h2 {
    color: #bf2e1b;
    font-family: Poppins;
    font-style: normal;
    font-weight: 700;
    line-height: 1.2;
    text-transform: capitalize;
    font-size: 18px;
    margin: 25px 0;
}

.blog-content h3 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 700;
    line-height: 1.2;
    text-transform: capitalize;
    font-size: 16px;
    margin: 15px 0;
}

.blog-content p {
    margin-bottom: 10px;
}

.blog-content img {
    max-width: 100%;
    height: auto;
    margin-bottom: 20px;
}

.blog-content table {
    width: 100%;
    border-collapse: collapse;
    margin: 0 auto;
    position: relative;
    overflow: scroll;
}

.blog-content th,
td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
}

.blog-content th {
    background-color: #f4f4f4;
    font-weight: bold;
}

.blog-content tr:nth-child(even) {
    background-color: #f9f9f9;
}

.blog-content tr:hover {
    background-color: #f1f1f1;
}

.blog-content td {
    vertical-align: top;
}

/* General List Styling */
.blog-content ul {
    list-style-type: none; /* Remove default bullets */
    padding: 0; /* Remove default padding */
    margin: 0; /* Remove default margin */
}

.blog-content li {
    margin: 10px 0; /* Add spacing between items */
    padding: 10px; /* Add padding for a clean look */
    border: 1px solid #ddd; /* Light border for each item */
    border-radius: 5px; /* Rounded corners */
    background-color: #f9f9f9; /* Light background color */
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

/* Hover Effects */
.blog-content li:hover {
    background-color: #f1f1f1; /* Slightly darker background on hover */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

/* Styling for Text in List Items */
.blog-content li p {
    margin: 0; /* Remove default margin for paragraphs */
    color: #333; /* Text color */
    font-size: 16px; /* Font size */
    line-height: 1.5; /* Line height for readability */
}

/* Optional Icons for List Items */
.blog-content li::before {
    content: "•"; /* Add a bullet-like icon */
    color: #d83a2b; /* Custom bullet color */
    font-size: 20px; /* Bullet size */
    margin-right: 10px; /* Space between icon and text */
}

/* Nested Lists */
.blog-content ul ul {
    margin-left: 20px; /* Indentation for nested lists */
    padding-left: 10px; /* Additional padding for nested lists */
    border-left: 2px solid #ddd; /* Line to indicate nesting */
}

.blog-content ul ul li {
    background-color: #ffffff; /* Different background for nested items */
}

.blog-content ul ul li:hover {
    background-color: #f9f9f9; /* Hover effect for nested items */
}

@media screen and (max-width: 768px) {
    .blog-title {
        font-size: 24px;
    }
    
}
/* Top Section */
.header-top {
    display: flex;
    height: 63px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    background: #bf2e1b;
}

.header-top p {
    color: #fff;
    text-align: center;
    line-height: 118.5%;
    letter-spacing: -0.176px;
}

/* Main Wrapper */
.header-main-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 14px 10px;
}

.header-main {
    display: flex;
    align-items: center;
    gap: 62px;
    width: 65%;
}

.header-logo img {
    max-width: 139px;
}

/* Navigation */
.header-nav {
    display: flex;
    align-items: center;
    gap: 15px;
}

.nav-link {
    text-decoration: none;
    color: #333;
    font-weight: 500;
    transition: color 0.3s, border-bottom 0.3s;
    font-size: clamp(8px, 10vw, 18px);
}

.nav-link-header {
    padding: 5px;
}

.nav-link:hover {
    color: #b71c1c;
    border-bottom: 2px solid #b71c1c;
}

/* Dropdown */
.dropdown {
    position: relative;
}

.dropdown-content {
    display: none;
    position: absolute;
    top: 25px;
    left: 0;
    background-color: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 200px;
    z-index: 100;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.dropdown-content a {
    display: block;
    padding: 10px 15px;
    text-decoration: none;
    color: #333;
}

.dropdown-content a:hover {
    color: #b71c1c;
}

/* Header Right Section */
.header-right {
    display: flex;
    align-items: center;
    gap: 25px;
}

.contact-info {
    display: flex;
    align-items: center;
    gap: 5px;
    text-decoration: none;
    color: #333;
    font-weight: 500;
    transition: color 0.3s;
    font-size: clamp(8px, 1vw, 16px);
}

.contact-info:hover {
    color: #b71c1c;
}

/* Search Input */
.search-container {
    position: relative;
}

.search-input {
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.search-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #888;
}

/* Mobile Burger Icon */
.burger {
    display: none;
}

@media (max-width: 1000px) {
    .header-top {
        height: 42px;
    }

    header {
        top: 0;
        left: 0;
        right: 0;
        z-index: 100;
        width: 100%;
        background-color: #fff;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        transition: top 0.3s;
    }

    /* Header Main Wrapper (Mobile) */
    .header-main-wrapper {
        display: flex;
        justify-content: space-between;
        padding: 14px 10px;
        z-index: 100;
        background-color: #fff;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    /* Mobile Header Main (Full Width) */
    .header-main {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 31px;
    }

    /* Mobile Navigation (Hidden by Default) */
    .header-nav {
        display: none;
        flex-direction: column;
        gap: 10px;
        width: 100%;
        position: absolute;
        top: 71px;
        left: 0;
        background-color: #fff;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        z-index: 101;
    }

    .header-nav.open {
        display: flex;
    }

    .nav-link {
        text-decoration: none;
        color: #333;
        font-weight: 500;
        font-size: 18px;
        text-align: center;
    }

    .nav-link-header {
        padding: 15px 20px;
    }

    .nav-link:hover {
        color: #b71c1c;
        border-bottom: 2px solid #b71c1c;
    }

    /* Burger Icon */
    .burger {
        background-color: #bf2e1b;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 59px;
        height: 54px;
        padding: 5px;
    }

    .burger-icon {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        cursor: pointer;
        z-index: 101;
        width: 30px;
        height: 20px;
    }
}

@media (max-width: 1500px) {
    .header-right {
        display: none;
    }

    .header-main {
        width: 100%;
    }
}

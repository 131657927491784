.achieving-success-wrapper {
    align-self: stretch;
}

.achieving-success {
    flex-shrink: 0;
    background: #f3f3f5;
    align-self: stretch;
    display: flex;
    align-items: center;
    padding: 77px 137px;
    overflow: hidden;
}

.achieving-layout {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: column;
}

.achieving-logo {
    top: 0;
    left: 0;
    width: 400px;
    height: 400px;
    background: url("/public/achieving-logo.png") no-repeat center center;
    background-size: contain;
    opacity: 1;
}

.achieving-content {
    display: flex;
    width: 30%;
    flex-direction: column;
    align-items: flex-start;
    gap: 17px;
    flex-shrink: 0;
}

.achieving-subtitle {
    align-self: stretch;
    color: #bf2e1b;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 118.5%; /* 21.33px */
    letter-spacing: -0.198px;
}

.achieving-heading-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 29px;
    align-self: stretch;
}

.achieving-heading {
    color: #0f0f0f;
    leading-trim: both;
    text-edge: cap;
    font-family: Poppins;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 118.5%; /* 47.4px */
    letter-spacing: -0.44px;
}

.achieving-highlight {
    color: #bf2e1b;
    leading-trim: both;
    text-edge: cap;
    font-family: Poppins;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 118.5%;
    letter-spacing: -0.44px;
}

.achieving-description {
    color: #646464;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 19.2px */
    letter-spacing: -0.176px;
}

.achieving-services {
    display: flex;
    width: 100%;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: flex-end;
    gap: 26px 13px;
    flex-shrink: 0;
    flex-wrap: wrap;
}

.service-card {
    display: flex;
    width: 270px;
    height: 160px;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    flex-shrink: 0;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 0px 10.1px 0px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.service-card:hover {
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
    background: #f8f9fa;
}

.icon {
    width: 100px;
    height: 94px;
    flex-shrink: 0;
    border-radius: 5px;
    background: #bf2e1b;
}

.service-text {
    display: flex;
    height: 105px;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
    flex-shrink: 0;
    align-self: stretch;
}

.service-title {
    flex-shrink: 0;
    align-self: left;
    color: #0f0f0f;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
}

.service-description {
    align-self: stretch;
    /* color: #646464; */
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    /* font-weight: 400; */
    /* line-height: 120%; */
    letter-spacing: -0.132px;
    border-radius: 10px;
    background-color: #bf2e1b;
}

@media (max-width: 768px) {
    .achieving-success-wrapper {
        height: auto;
    }

    .achieving-success {
        padding: 30px 16px;
        height: auto;
        display: flex;
        flex-direction: column;
        gap: 30px !important;
    }

    .achieving-layout {
        align-items: flex-start;
        flex-direction: column;
        gap: 16px;
        overflow: scroll;
    }

    .achieving-content {
        color: #646464;
        display: flex;
        flex-direction: column;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 16.8px */
        letter-spacing: -0.154px;
        width: 100%;
        gap: 10px;
    }

    .achieving-heading-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16.285px;
        flex-shrink: 0;
        align-self: stretch;
    }

    .achieving-heading {
        align-self: stretch;
        color: #000;
        leading-trim: both;
        text-edge: cap;
        font-family: Poppins;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 118.5%; /* 28.44px */
        letter-spacing: -0.264px;
    }

    .achieving-highlight {
        color: #bf2e1b;
        leading-trim: both;
        text-edge: cap;
        font-family: Poppins;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 118.5%;
        letter-spacing: -0.264px;
    }

    .achieving-services {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        padding: 7px;
        height: 420px;
        width: 100%;
        overflow-x: auto;
        scroll-snap-type: x mandatory;
    }

    .service-card {
        padding: 8px;
        flex: 1 0 calc(50% - 16px);
        max-width: 50%;
        scroll-snap-align: start;
        height: 130px;
    }

    .service-title {
        height: auto;
        font-size: 16px;
    }

    .service-text {
        height: auto;
    }

    .service-description {
        font-size: 10px;
        padding: 5px;
    }
}

.container {
    display: flex;
    flex-direction: column;
    gap: 3px;
    width: 100%;
    background-color: #bf2e1b;
    clip-path: polygon(0 0, 100% 0, 100% 70%, 0 100%);
    border-bottom-left-radius: 30px 30px;
    padding: 40px 0;
}
.titleWrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    padding-left: 55px;
    background-color: #bf2e1b;
    clip-path: polygon(0 0, 100% 0, 100% 70%, 0 100%);
    border-bottom-left-radius: 30px 30px;
}

.title {
    color: #fff;
    font-size: 32px;
    font-weight: 700;
}
.description {
    color: #fff;
    padding-left: 55px;
}

@media (max-width: 768px) {
    .titleContainer {
        margin-top: 10px;
        padding-left: 55px;
    }
    .title {
        font-size: 22px;
    }
}

/* Trusted Associations Section */
.trusted-associations {
    display: flex;
    padding: 60px 137px;
    flex-direction: column;
    align-items: flex-start;
    gap: 43px;
    align-self: stretch;
    background: #FFF;
    overflow: hidden;
    position: relative;
    width: 100%;
}

.trusted-title-wrapper {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 53px;
}

.trusted-title {
    color: #0F0F0F;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-family: Poppins;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 118.5%; /* 35.55px */
    letter-spacing: -0.33px;
}

/* Avoid lagging by reducing grid animation duration */
.logo-grid {
    height: 100px;
    display: flex;
    gap: 80px;
    animation: scrollLoop 100s linear infinite; /* Reduced duration */
    will-change: transform; /* Optimized rendering */
}

.logo {
    height: 80px;
    max-height: 100px;
    width: 150px;
    max-width: 150px;
    object-fit: contain;
    opacity: 0; /* Initially hidden */
    animation: fadeIn 1s ease-out forwards; /* Shorter fade-in animation */
    /* animation-delay: var(--delay); Dynamic delay */
    cursor: pointer; /* Pointer cursor on hover */
}

/* Keyframes for fade-in effect */
@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes scrollLoop {
    from {
        transform: translate3d(0, 0, 0);
    }
    to {
        transform: translate3d(-50%, 0, 0);
    }
}

/* Duplicate logos for seamless scrolling */
.logo-grid::after {
    content: '';
    display: inline-flex;
    gap: 80px;
}

@media (max-width: 768px) {
    .trusted-associations {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 29px;
        flex-shrink: 0;
        align-self: stretch;
        padding: 46px 16px;
    }

    .trusted-title {
        color: #0F0F0F;
        text-align: center;
        leading-trim: both;
        text-edge: cap;
        font-family: Poppins;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 118.5%; /* 28.44px */
        letter-spacing: -0.264px;
    }

    .logo-grid {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        align-content: center;
        gap: 40px;
        flex-shrink: 0;
        overflow: scroll;
    }

}
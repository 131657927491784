.empower-section {
    text-align: center;
    background-color: #f7f7f7;
    padding: 60px 137px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
}

.empower-text {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.empower-title {
    color: #BF2E1B;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-family: Poppins;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 118.5%; /* 47.4px */
    letter-spacing: -0.44px;
}

.empower-subtitle {
    align-self: stretch;
    color: #434345;
    text-align: center;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 118.5%; /* 28.44px */
    letter-spacing: -0.264px;
}

@media (max-width: 768px) {
    .empower-section {
        padding: 28px 16px;
        gap: 24px;
        height: 300px;
    }

    .empower-title {
        color: #BF2E1B;
        text-align: center;
        leading-trim: both;
        text-edge: cap;
        font-family: Poppins;
        font-size: 23.805px;
        font-style: normal;
        font-weight: 600;
        line-height: 118.5%; /* 28.209px */
        letter-spacing: -0.262px;
    }

    .empower-subtitle {
        color: #434345;
        text-align: center;
        font-family: Poppins;
        font-size: 14.283px;
        font-style: normal;
        font-weight: 400;
        line-height: 118.5%; /* 16.925px */
        letter-spacing: -0.157px;
    }

}































































































































.learn-more-btn {
    background-color: #bf2e1b;
    color: #fff;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 600;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s;
































































































































































































































































































































































































































































































































































































































































































































































































































}

.learn-more-btn:hover {
    background-color: #a12617;
}

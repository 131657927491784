.testimonials {
    display: flex;
    padding: 60px 137px;
    flex-direction: column;
    align-items: flex-start;
    gap: 78px;
    align-self: stretch;
    background: #fff;
}

.testimonials-up-wrapper {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 40px;
}

.testimonials-up {
    display: flex;

    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    flex-shrink: 0;
}

.testimonials-title {
    align-self: stretch;
    color: #bf2e1b;
    text-align: center;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 118.5%; /* 28.44px */
    letter-spacing: -0.264px;
}

.testimonials-subtitle {
    align-self: stretch;
    color: #0f0f0f;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-family: Poppins;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 118.5%; /* 47.4px */
    letter-spacing: -0.44px;
}

.testimonials-container-wrapper {
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px; */
    /* align-self: stretch; */
    /* overflow: scroll; */
    position: relative;
    width: 100%;
}

.testimonials-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-self: flex-start;
    gap: 20px;
    display: flex;
    overflow-x: scroll;
    scroll-behavior: smooth;
    padding: 10px 0;
}

.testimonial-card {
    display: flex;
    flex: 0 0 auto;
    width: 440px;
    height: 440px;
    padding: 40px 28px 30px 28px;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    border-radius: 10px;
    background: #f6f6f8;
}

.arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    font-size: 2rem;
    cursor: pointer;
    z-index: 10;
    color: #bf2e1b;
}

.left-arrow {
    left: 10px;
    transform: translateX(-250%);
}

.right-arrow {
    right: 10px;
    transform: translateX(250%);
}

.testimonial-logo {
    height: 58px;
    flex-shrink: 0;
}

.name {
    color: #0f0f0f;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 137%; /* 30.14px */
    letter-spacing: -0.242px;
}

.role {
    color: #0f0f0f;
    leading-trim: both;
    text-edge: cap;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 137%;
    letter-spacing: -0.154px;
}

.feedback {
    color: #434345;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 118.5%; /* 16.59px */
    letter-spacing: -0.154px;
}

@media (max-width: 1568px) {
    .testimonials {
        padding: 60px 50px;
    }
}

@media (max-width: 868px) {
    .testimonials {
        padding: 30px 16px;
        gap: 24px;
    }

    .testimonials-container {
        gap: 16px;
    }

    .testimonials-title {
        align-self: stretch;
        color: #bf2e1b;
        text-align: center;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 118.5%; /* 18.96px */
        letter-spacing: -0.176px;
    }

    .testimonials-subtitle {
        align-self: stretch;
        color: #0f0f0f;
        text-align: center;
        leading-trim: both;
        text-edge: cap;
        font-family: Poppins;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 118.5%; /* 28.44px */
        letter-spacing: -0.264px;
    }

    .testimonial-card {
        display: flex;
        width: 360px;
        height: 360px;
        padding: 30px;
        flex-direction: column;
        align-items: center;
        gap: 15.65px;
        flex-shrink: 0;
        border-radius: 7.825px;
        background: #f6f6f8;
    }

    .name {
        color: #0f0f0f;
        text-align: center;
        leading-trim: both;
        text-edge: cap;
        font-family: Poppins;
        font-size: 17.215px;
        font-style: normal;
        font-weight: 600;
        line-height: 137%; /* 23.585px */
        letter-spacing: -0.189px;
    }

    .role {
        color: #0f0f0f;
        text-align: center;
        leading-trim: both;
        text-edge: cap;
        font-family: Poppins;
        font-size: 10.955px;
        font-style: normal;
        font-weight: 400;
        line-height: 137%;
        letter-spacing: -0.121px;
    }

    .feedback {
        color: #434345;
        text-align: center;
        leading-trim: both;
        text-edge: cap;
        font-family: Poppins;
        font-size: 10.955px;
        font-style: normal;
        font-weight: 400;
        line-height: 118.5%; /* 12.982px */
        letter-spacing: -0.121px;
    }

    .arrow {
        display: none;
    }
}

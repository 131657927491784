.testimonials_container_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    align-self: stretch;
    overflow: scroll;
    margin: 0 5px;
}

.testimonials_container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px 30px;
    align-items: flex-start;
}

@media (max-width: 1200px) {
    .testimonials_container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 768px) {
    .testimonials_container {
        grid-template-columns: 1fr;
    }
}

.testimonial_card {
    display: flex;
    width: 100%;
    height: 440px;
    padding: 40px 28px 80px 28px;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    border-radius: 10px;
    background: #f6f6f8;
}

.testimonial_logo {
    height: 58px;
    flex-shrink: 0;
}

.name {
    color: #0f0f0f;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 137%; /* 30.14px */
    letter-spacing: -0.242px;
}

.role {
    color: #0f0f0f;
    leading-trim: both;
    text-edge: cap;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 137%;
    letter-spacing: -0.154px;
}

.feedback {
    color: #434345;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 118.5%; /* 16.59px */
    letter-spacing: -0.154px;
}

@media (max-width: 768px) {
    .testimonials_container {
        align-self: flex-start;
        gap: 16px;
    }

    .testimonials_title {
        align-self: stretch;
        color: #bf2e1b;
        text-align: center;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 118.5%; /* 18.96px */
        letter-spacing: -0.176px;
    }

    .testimonial_card {
        display: flex;
        width: 100%;
        height: 360px;
        padding: 31.3px 21.91px 62.6px 21.91px;
        flex-direction: column;
        align-items: center;
        gap: 15.65px;
        flex-shrink: 0;
        border-radius: 7.825px;
        background: #f6f6f8;
    }

    .name {
        color: #0f0f0f;
        text-align: center;
        leading-trim: both;
        text-edge: cap;
        font-family: Poppins;
        font-size: 17.215px;
        font-style: normal;
        font-weight: 600;
        line-height: 137%; /* 23.585px */
        letter-spacing: -0.189px;
    }

    .role {
        color: #0f0f0f;
        text-align: center;
        leading-trim: both;
        text-edge: cap;
        font-family: Poppins;
        font-size: 10.955px;
        font-style: normal;
        font-weight: 400;
        line-height: 137%;
        letter-spacing: -0.121px;
    }

    .feedback {
        color: #434345;
        text-align: center;
        leading-trim: both;
        text-edge: cap;
        font-family: Poppins;
        font-size: 10.955px;
        font-style: normal;
        font-weight: 400;
        line-height: 118.5%; /* 12.982px */
        letter-spacing: -0.121px;
    }
}

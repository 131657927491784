.insights-section {
    text-align: center;
    font-family: "Poppins", sans-serif;
}

.section-title {
    color: #bf2e1b;
    text-align: center;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 118.5%; /* 28.44px */
    letter-spacing: -0.264px;
}

.section-subtitle {
    color: #333;
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 30px;
}

.insights-container {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.insight-card {
    background-color: #fff;
    overflow: hidden;
    width: 420px;
    margin: 10px;
    text-align: left;
}

.image-wrapper {
    position: relative;
}

.insight-image {
    width: 100%;
    height: 223px;
    object-fit: cover;
    border-radius: 12px;
    margin-bottom: 10px;
}

.overlay-text {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    color: #fff;
    padding: 19px 21px;
    background: rgba(30, 30, 30, 0.8);
    height: 44%;
}

.card-title {
    color: #fff;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 21.6px */
    letter-spacing: -0.198px;
    margin: 0;
}

.category {
    font-size: 12px;
    color: #646464;
    margin: 15px 15px 5px 0;
}

.description-wrapper {
    border-left: 2px solid #bf2e1b;
    padding-left: 10px;
}

.description {
    color: #434345;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 118.5%; /* 16.59px */
    letter-spacing: -0.154px;
}

@media (max-width: 768px) {
    .insights-section {
        padding: 30px 16px;
    }

    .section-title {
        color: #bf2e1b;
        text-align: center;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 118.5%; /* 18.96px */
        letter-spacing: -0.176px;
        margin-bottom: 16px;
    }

    .section-subtitle {
        color: #0f0f0f;
        text-align: center;
        leading-trim: both;
        text-edge: cap;
        font-family: Poppins;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 118.5%; /* 28.44px */
        letter-spacing: -0.264px;
        margin-bottom: 0;
    }

    .insights-container {
        overflow: scroll;
        flex-wrap: nowrap;
        width: auto;
    }

    .insight-card {
        flex-shrink: 0;
        width: 80%;
    }
}

.hero-section {
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    width: 100%;
    height: auto;
    position: relative;
    overflow: hidden;
}

/* Background Logo */
.hero-logo {
    top: 0;
    left: 0;
    width: 400px;
    height: 400px;
    background: url("/public/hero-logo.png") no-repeat center center;
    background-size: contain;
    opacity: 1;
    align-self: flex-end;
}

/* Content Section */
.hero-content {
    display: flex;
    width: 40%;
    flex-direction: column;

    gap: 29px;
    height: 100%;
    align-self: center;
    margin-left: -200px;
}

.hero-heading {
    color: #434345;
    font-family: Poppins;
    font-size: 42px;
    font-style: normal;
    font-weight: 700;
    line-height: 118.5%; /* 49.77px */
    letter-spacing: -0.462px;
    text-transform: capitalize;
}

.hero-highlight {
    color: #bf2e1b;
    font-family: Poppins;
    font-size: 42px;
    font-style: normal;
    font-weight: 700;
    line-height: 118.5%;
    letter-spacing: -0.462px;
    text-transform: capitalize;
}

.buttons {
    display: flex;
    gap: 20px;
}

.button {
    padding: 12px 20px;
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    font-weight: 600;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease-in-out; /* Smooth transition */
    box-shadow: 0px 0px 10.1px 0px rgba(0, 0, 0, 0.2); /* Subtle shadow */
    width: 250px;
    height: 60px;
}

.button:hover {
    transform: translateY(-1px); /* Move button slightly upward */
    background-color: #bf2e1b;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2); /* Enhanced shadow */
}

.button:hover a {
    color: #fff; /* Change text color on hover */
}

.button.primary {
    background-color: #bf2e1b;
    color: #fff;
}

.button.secondary {
    background-color: #bf2e1b;
    color: #fff;
}

/* Image Grid Section */
.image-grid {
    max-width: 50%;
    display: flex;
    justify-content: flex-end;
}

.image-grid-img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
}

/* Mobile-Specific Styles */
@media (max-width: 768px) {
    .hero-section {
        height: 30vh;
        width: 100%;
        justify-content: flex-end;
    }

    .hero-logo {
        visibility: hidden;
        display: none;
    }

    .image-grid {
        position: absolute;
        left: 0;
        top: 0;
        max-width: 100%;
        object-fit: cover;
        height: auto;
        opacity: 0.8;
    }

    .hero-content {
        position: relative;

        padding: 30px 15px;
        background: linear-gradient(
            183.58deg,
            rgba(0, 0, 0, 0) 1.68%,
            rgba(0, 0, 0, 0.78) 61.42%
        );

        z-index: 1;
        width: 100%;
        margin-left: 0;
        align-self: flex-end;
        display: flex;
        justify-content: flex-end;
        gap: 16px;
    }

    .hero-heading {
        color: #fff;
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 118%; /* 23.6px */
        letter-spacing: -0.2px;
        text-transform: capitalize;
    }

    .hero-highlight {
        font-size: 20px;
        color: white;
    }

    .buttons {
        width: 85%;
        gap: 20px;
    }
    
    .button {
        display: flex;
        width: 100%;
        height: 48px;
        padding: 11.361px 15.418px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        border-radius: 4.057px;
        background: #bf2e1b;
        color: #fff;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 18.259px */
        letter-spacing: -0.134px;

    }
}

@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
    font-family: "Poppins", serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.polygons {
    background-image: url("/public/polygon.png");
    width: 100%;
    height: 100%;
}
.containerApp {
    margin: 0 auto !important;
    max-width: 1680px !important;
    padding: 0 10px;
}
::-webkit-scrollbar {
    display: none;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.qxCTlb {
    height: 52px !important;
}

@media screen and (max-width: 768px) {
    .hur54b {
        padding: 20px !important;
    }

    .Xfsokf {
        right: 0 !important;
        width: auto !important;
    }
    
}